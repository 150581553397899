import React, { useContext, useEffect, useState, useRef } from 'react';

import { NavLink } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';
import Utils from './Utils';
import NavigatorOrderDetail from './NavigatorOrderDetail';
import CustomerFeedback from './CustomerFeedback';
import useCustomerStampCard from './hooks/useCustomerStampCard';
import TimeLeft from './TimeLeft';
import CustomerComplaint from './CustomerComplaint';
import ShopsContext from './ShopsContext';
import useOnScreen from './useOnScreen';
import Common from './Common';
import NoBagBadge from './NoBagBadge';
import PayLaterUnPaidBadge from './PayLaterUnPaidBadge';
import 'react-confirm-alert/src/react-confirm-alert.css';

type NavigatorOrderProps = {
  orderId: string;
  order: firebase.firestore.DocumentData;
  customerId: string;
  stampCardSnapshot: firebase.firestore.DocumentSnapshot | null;
  elementItems: firebase.firestore.DocumentSnapshot[] | null;
  showLid?: boolean;
};

const NavigatorOrder = React.memo(
  (props: NavigatorOrderProps) => {
    console.log('RELOADED navigator order');

    const { shops, currentShop } = useContext(ShopsContext);
    const storeContainer = StoreContainer.useContainer();
    const { order } = props;
    const [posting, setPosting] = useState(false);
    const [messageError, setMessageError] = useState('');

    const handleDoneClickItem = async () => {
      if (order.cooked_at) {
        const data = {
          done_by_staff: true,
          done_by_staff_at: firebase.firestore.Timestamp.now(),
          navigator_id: storeContainer.crewId,
        };

        const batch = firebase.firestore().batch();
        batch.update(firebase.firestore().collection('orders').doc(props.orderId), data);
        await batch.commit();
      } else {
        window.alert('調理完了のものしか完了にできません。'); // eslint-disable-line no-alert
      }
    };

    const handleDoneClickItemWithConfirm = async () => {
      const emptyDiv = () => <div />;
      const options = {
        title: 'お渡し完了していますか？',
        buttons: [
          {
            label: '完了',
            onClick: async () => {
              await handleDoneClickItem();
            },
          },
          {
            label: '戻る',
            onClick: () => {},
          },
        ],
        childrenElement: () => emptyDiv(),
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      };
      confirmAlert(options);
    };

    const handleReportDelayClickItem = async () => {
      if (isDelayed) {
        const emptyDiv = () => <div />;
        const options = {
          title: 'お客様にお取り置き時間をご連絡します',
          buttons: [
            {
              label: '連絡する',
              onClick: async () => {
                const apiEndPoint = `${process.env.REACT_APP_api_server}/orders/${props.orderId}/notify_delay`;
                const auth = firebase.auth();
                setPosting(true);

                auth.currentUser!.getIdToken().then((token) => {
                  fetch(apiEndPoint, {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({}),
                  })
                    .then(async (response) => {
                      const responseJson = await response.json();
                      if (response.status === 200) {
                        // nothing to do
                      } else {
                        setMessageError(responseJson.error.message);
                      }
                      setPosting(false);
                    })
                    .catch(() => {
                      setMessageError('更新に失敗しました');
                      setPosting(false);
                    });
                });
              },
            },
            {
              label: '戻る',
              onClick: () => {},
            },
          ],
          childrenElement: () => emptyDiv(),
          closeOnEscape: true,
          closeOnClickOutside: true,
          willUnmount: () => {},
          onClickOutside: () => {},
          onKeypressEscape: () => {},
        };
        confirmAlert(options);
      } else {
        window.alert('遅延中のもののみです'); // eslint-disable-line no-alert
      }
    };

    const [customer, setCustomer] = useState<firebase.firestore.DocumentData>();
    const [isDelayed, setIsDelayed] = useState(false);

    useEffect(() => {
      firebase
        .firestore()
        .collection('customers')
        .doc(props.customerId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setCustomer(doc.data());
          }
        });
    }, [props.customerId]);

    const customserStampCard = useCustomerStampCard(props.stampCardSnapshot, props.customerId);

    const salesChannelIconName = (salesChanel: string) => {
      switch (salesChanel) {
        case 'takeout':
          return 'fa-store';
        case 'eatin':
          return 'fa-utensils';
        case 'stand':
          return 'fa-map-marker-alt';
        case 'office_delivery':
        case 'catering':
          return 'fa-building';
        default:
          return 'fa-question';
      }
    };

    const formatItems = () => {
      const names: Array<JSX.Element> = [];
      let number = 0;
      const multipleOrder = order.items.length > 1 || order.items[0].count > 1;
      for (const item of order.items) {
        for (let i = 0; i < item.count; i += 1) {
          number += 1;
          names.push(
            <div key={`order-item-${i}`}>
              <span className="badge badge-light">
                {Common.pickupPoint(props.order)}
                No. {order.code}
                {multipleOrder ? `-${number}` : ''}
              </span>
              &nbsp;
              <small style={{ fontWeight: 'normal' }}>{item.curry.name}</small>
            </div>,
          );
        }
      }
      return names;
    };

    const showElementItems = (items: Array<any>) => {
      if (!navigatorElementItems) {
        return <></>;
      }

      const showCounts: { [key: string]: { count: number; prefix: string } } = {};

      for (const item of items) {
        const itemCount = item.count;
        for (const component of item.curry.components) {
          for (const element of component.elements) {
            if (!(element.quantity > 0 && element.size > 0)) {
              continue;
            }

            const foundElementItem = navigatorElementItems.find(
              (elementItem) => elementItem.data()!.id === element.item_id,
            );

            if (foundElementItem) {
              showCounts[element.item_id] ||= { prefix: foundElementItem.data()!.kitchen_navigator_prefix, count: 0 };
              showCounts[element.item_id].count += itemCount * element.quantity * element.size;
            }
          }
        }
      }

      return Object.keys(showCounts).map((elementItemId) => (
        <span key={`element-item-${elementItemId}`} className="mr-1">
          <small>
            {showCounts[elementItemId].prefix} x {showCounts[elementItemId].count}
          </small>
        </span>
      ));
    };

    const [isVisibleOnce, setIsVisibleOnce] = useState(false);
    const ref: any = useRef<HTMLDivElement>();
    const isVisible = useOnScreen(ref);

    const navigatorElementItems = props.elementItems?.filter((item) => item.data()!.kitchen_navigator_prefix);

    useEffect(() => {
      if (isVisible) {
        setIsVisibleOnce(true);
      }
    }, [isVisible]);

    let pickupDeadline: Dayjs | null = null;
    if (order.sales_channel === 'takeout' && order.estimated_at && order.cooked_at && !order.done_by_staff) {
      const deadlines: Dayjs[] = [];

      if (currentShop?.data()!.today_closing_time) {
        deadlines.push(dayjs(currentShop?.data()!.today_closing_time.toDate()).add(5, 'minutes'));
      }
      deadlines.push(dayjs(order.estimated_at.toDate()).add(30, 'minutes'));
      pickupDeadline = dayjs.min(deadlines);
    }

    useEffect(() => {
      if (pickupDeadline && !order.disposing_at) {
        setIsDelayed(dayjs().isAfter(pickupDeadline));
        const interval = setInterval(() => {
          setIsDelayed(dayjs().isAfter(pickupDeadline));
        }, 60000);
        return () => {
          clearInterval(interval);
        };
      }
      return () => {};
    }, [pickupDeadline, order.disposing_at]);

    return (
      <>
        <div ref={ref} className="card navigator-order mb-2">
          <div className="card-body py-3">
            <div className="row">
              <div className="col-2 text-center px-1 d-flex flex-column">
                {order.status === 'ordered' && <small>{Utils.orderStatusName(order.status)}</small>}

                {order.status === 'cooking' && (
                  <>
                    <small>{Utils.orderStatusName(order.status)}</small>
                    <br />
                    {order.cooking_slots && order.cooking_slots.length > 0 && (
                      <TimeLeft
                        deadline={
                          props.order.delivery_departure_due_time
                            ? props.order.delivery_departure_due_time.toDate()
                            : order.cooking_slots[order.cooking_slots.length - 1].time_until.toDate()
                        }
                      />
                    )}
                  </>
                )}

                {order.cooked_at && (
                  <>
                    {order.done_by_staff ? (
                      <small>完了済</small>
                    ) : order.batch_delivery ? (
                      order.delivering ? (
                        <>
                          <small>配達中</small>
                          <br />
                          <TimeLeft deadline={order.pickup_until_at.toDate()} />
                        </>
                      ) : (
                        <>
                          {order.batch_delivery &&
                            (order.nth_delivery_orders_in_this_slot - order.total_curries_in_this_order === 0 ? (
                              <>
                                出発予定
                                <br />
                                時間まで
                                <br />
                                {order.delivery_departure_due_time && (
                                  <TimeLeft deadline={order.delivery_departure_due_time.toDate()} />
                                )}
                              </>
                            ) : (
                              <></>
                            ))}
                        </>
                      )
                    ) : (
                      <>
                        {order.notified_at ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={isDelayed ? handleDoneClickItemWithConfirm : handleDoneClickItem}
                          >
                            完了
                          </button>
                        ) : (
                          <small>準備中</small>
                        )}
                        {order.disposing_at ? (
                          <div className="mt-2">
                            連絡済
                            <br />
                            {dayjs(order.disposing_at.toDate()).tz('Asia/Tokyo').format('H時m分')}廃棄
                          </div>
                        ) : (
                          isDelayed && (
                            <button
                              type="button"
                              className="btn btn-warning mt-4 px-1 py-2"
                              onClick={handleReportDelayClickItem}
                            >
                              取り置き連絡
                            </button>
                          )
                        )}
                        {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}
                      </>
                    )}
                    {!order.paid && order.paylater && (
                      <>
                        <br />
                        <br />
                        <PayLaterUnPaidBadge />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-auto pl-1">
                        <h5>
                          <NavLink to={`/orders/${props.orderId}`} target="_blank" activeClassName="active">
                            {Common.pickupPoint(props.order)}
                            <small>No.</small>
                            {order.code}
                          </NavLink>
                          <NavLink
                            to={`/customers/${order.customer_id}`}
                            target="_blank"
                            activeClassName="active"
                            className="ml-2 mr-1"
                          >
                            <span className="navigator-order-icon-text mr-1">
                              {order.nickname || '(ニックネーム未設定)'}
                              &nbsp;<small>様</small>
                            </span>
                          </NavLink>
                        </h5>
                      </div>
                      {order.shop_id !== order.kitchen_shop_id && (
                        <div className="col-auto pl-0">
                          <small>
                            <i className={`fas ${salesChannelIconName(order.sales_channel)} fa-xs`} />
                            &nbsp;
                            {shops[order.shop_id].data()!.short_name}
                            {order.delivery_group && ` (${order.delivery_group?.name})`}
                            {order.batch_delivery &&
                              order.nth_delivery_orders_in_this_slot &&
                              ` (${order.nth_delivery_orders_in_this_slot}/${order.number_of_delivery_orders_in_this_slot})`}
                          </small>
                        </div>
                      )}{' '}
                    </div>
                  </div>

                  <div className="col-auto text-right" style={{ lineHeight: 1.4 }}>
                    {order.batch_delivery && (
                      <span className="badge badge-warning mr-1" role="alert">
                        <small>配達</small>
                      </span>
                    )}

                    {order.sales_channel === 'eatin' && (
                      <span className="badge badge-dark mr-1" role="alert">
                        <small>イートイン</small>
                      </span>
                    )}

                    {order.estimated_at ? (
                      <>
                        <i className="far fa-clock" />
                        &nbsp;
                        {dayjs(order.estimated_at.toDate()).tz('Asia/Tokyo').format('HH:mm')}
                      </>
                    ) : (
                      <>
                        <i className="far fa-clock" />
                        &nbsp;
                        {dayjs(order.pickup_at.toDate()).tz('Asia/Tokyo').format('HH:mm')} -
                        {dayjs(order.pickup_until_at.toDate()).tz('Asia/Tokyo').format('HH:mm')}
                      </>
                    )}
                  </div>
                </div>
                <div className="row px-0 py-1">
                  <div className="col">
                    {order.bag === false && (
                      <span className="navigator-order-icon-text">
                        <NoBagBadge />
                      </span>
                    )}
                    {showElementItems(order.items)}
                    {order.total_curries_in_this_order && (
                      <span className="navigator-order-icon-text">
                        <i className="fas fa-utensil-spoon fa-xs" />
                        &nbsp;
                        {order.total_curries_in_this_order}
                      </span>
                    )}
                    {order.nth_order && (
                      <span className="navigator-order-icon-text">
                        <i className="far fa-walking fa-xs" />
                        &nbsp;
                        {order.nth_order}
                      </span>
                    )}
                    {order.invitation_code && (
                      <span className="navigator-order-icon-text">
                        <i className="fas fa-user-friends fa-xs" /> {order.invitation_code}
                      </span>
                    )}
                    {props.stampCardSnapshot && isVisibleOnce && (
                      <span
                        className={`navigator-order-icon-text ${
                          customserStampCard && customserStampCard.totalStampCount > customserStampCard.maxStampCount
                            ? 'text-muted'
                            : ''
                        }`}
                      >
                        <i className="fas fa-stamp fa-xs" />
                        &nbsp;
                        {customserStampCard
                          ? `${customserStampCard.totalStampCount}/${customserStampCard.maxStampCount}`
                          : '-/-'}
                      </span>
                    )}
                    {customer?.notification_permission &&
                      (customer?.notification_permission === 'granted' ? (
                        <span className="navigator-order-icon-text">
                          <i className="fas fa-bell fa-xs" />
                          &nbsp;通知ON
                        </span>
                      ) : (
                        <span className="navigator-order-icon-text">
                          <i className="fas fa-bell-slash fa-xs" />
                          &nbsp;通知OFF
                        </span>
                      ))}
                    <span className="navigator-order-icon-text">
                      <i className="fas fa-yen-sign fa-xs" />
                      {order.amount.toLocaleString()}
                      <small className="text-muted">({order.coupon_code ? order.coupon_code : 'クーポンなし'})</small>
                    </span>
                    {order.churn_status === 'WILL_CHURN' && (
                      <span style={{ color: 'red' }}>
                        <i className="fas fa-exclamation-triangle fa-xs" />
                      </span>
                    )}
                    {order.version_warning && (
                      <span className="navigator-order-icon-text">
                        <i className="fas fa-exclamation-triangle fa-xs" />
                        &nbsp;
                        <i className={`fab fa-${order.os === 'ANDROID' ? 'android' : 'apple'}`} />
                      </span>
                    )}
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col">{formatItems()}</div>
                </div>
                {order.navigator_talks?.length > 0 && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        💁&nbsp;<strong>トーク</strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        {order.navigator_talks?.map((talk) => (
                          <li key={talk.navigator_talk_id} className="list-group-item py-2 small text-muted">
                            <div style={{ whiteSpace: 'pre-wrap' }}>{talk.message}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {props.showLid && order.navigator_lid_message && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        <strong>
                          フタ
                          <br />
                          メッセージ
                        </strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        <li className="list-group-item py-2 small text-muted">
                          <div style={{ whiteSpace: 'pre-wrap' }}>{order.navigator_lid_message}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {order.request && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        🙏&nbsp;<strong>ご要望</strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        <li className="list-group-item py-2 small text-muted">{order.request}</li>
                      </ul>
                    </div>
                  </div>
                )}
                {order.cs_requests?.length > 0 && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        📢&nbsp;<strong>CS</strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        {order.cs_requests
                          .filter((request) => request.types.includes('navigator'))
                          .map((request) => (
                            <li key={request.id} className="list-group-item py-2 small text-muted">
                              {request.request}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}

                {order.nth_order > 1 && isVisibleOnce && (
                  <>
                    <CustomerFeedback
                      customerId={order.customer_id}
                      currentTime={order.ordered_at.toDate()}
                      onlyAfterLastOrder
                      before={order.ordered_at.toDate()}
                    />
                    <CustomerComplaint
                      customerId={order.customer_id}
                      currentTime={order.ordered_at.toDate()}
                      onlyAfterLastOrder
                      before={order.ordered_at.toDate()}
                    />
                  </>
                )}

                {order.nth_order > 1 && <NavigatorOrderDetail orderId={props.orderId} order={order} />}
              </div>
            </div>
          </div>
        </div>
        <WindmillSpinnerOverlay loading={posting} message="送信中" />
      </>
    );
  },
  (prevProps, nextProps) => {
    const ret =
      prevProps.orderId === nextProps.orderId &&
      prevProps.order.status === nextProps.order.status &&
      prevProps.order.paid === nextProps.order.paid &&
      prevProps.order.notified_at?.seconds === nextProps.order.notified_at?.seconds &&
      prevProps.order.disposing_at?.seconds === nextProps.order.disposing_at?.seconds;
    return ret;
  },
);

export default NavigatorOrder;
