import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import AdminMessageNav from './AdminMessageNav';

function FileUpload() {
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState<string>();
  const [error, setError] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError('');
    setFileUrl('');

    const file = e.target.files?.[0];
    if (!file) return;

    let fileName;
    if (file.type === 'image/png') {
      fileName = `${new Date().getTime()}.png`;
    } else if (file.type === 'image/jpeg') {
      fileName = `${new Date().getTime()}.jpg`;
    } else {
      setError('PNGまたはJPEG形式の画像のみアップロード可能です');
      return;
    }

    const path = '/messages';
    const fullPath = `${path}/${fileName}`;
    const uploadTask = firebase.storage().ref(fullPath).put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setWaitingMessage(`${progress}%`);
        setLoading(true);
      },
      (uploadError) => {
        console.error(uploadError);
        setError('アップロード中にエラーが発生しました');
        setLoading(false);
      },
      async () => {
        if (uploadTask.snapshot.totalBytes > 500000) {
          setError('画像サイズは500キロバイト以内にしてください');
        } else {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          setFileUrl(url);
        }
        setLoading(false);
      },
    );
  };

  return (
    <div className="container-fluid h-100">
      <AdminMessageNav />
      <h3>ファイルアップロード</h3>

      <div className="card mb-4">
        <div className="card-body">
          <h5 className="card-title">使い方</h5>
          <ol>
            <li>「ファイルを選択」ボタンをクリックして画像を選択します</li>
            <li>アップロードが完了すると、画像のURLが表示されます</li>
          </ol>
          <p>
            <strong>注意: </strong>
            <ul>
              <li>PNGまたはJPEG形式の画像のみアップロード可能です</li>
              <li>画像サイズは500キロバイト以内にしてください</li>
            </ul>
          </p>
        </div>
      </div>

      <div className="form-group">
        <input
          type="file"
          className="form-control-file"
          accept="image/jpeg, image/png"
          onChange={handleUpload}
          disabled={loading}
        />
      </div>

      {error && <div className="alert alert-danger mt-3">{error}</div>}

      {fileUrl && (
        <div className="mt-4">
          <h4>アップロードされた画像</h4>

          <div className="form-group mt-3">
            <label>画像URL:</label>
            <div className="input-group">
              <input type="text" className="form-control" value={fileUrl} readOnly />
              <div className="input-group-append">
                <button
                  className={`btn ${isCopied ? 'btn-success' : 'btn-outline-secondary'}`}
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(fileUrl);
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 2000);
                  }}
                >
                  {isCopied ? 'コピー✔ ' : 'コピー'}
                </button>
              </div>
            </div>
          </div>
          <img src={fileUrl} alt="Uploaded" style={{ maxWidth: '400px' }} />
        </div>
      )}

      <WindmillSpinnerOverlay loading={loading} message={waitingMessage} />
    </div>
  );
}

export default FileUpload;
