import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useSalesShops = (): Array<firebase.firestore.DocumentSnapshot> | null => {
  const [useSalesShopsSnapshot, setSalesShopsSnapshot] = useState<Array<firebase.firestore.DocumentSnapshot> | null>(
    null,
  );

  useEffect(() => {
    const query: firebase.firestore.CollectionReference | firebase.firestore.Query = firebase
      .firestore()
      .collection('sales_shops')
      .where('qr_bento_sales_preset_ids', '!=', []);

    query.get().then((snapshot) => {
      console.log('get sales_shops');
      setSalesShopsSnapshot(snapshot.docs);
    });
  }, []);

  return useSalesShopsSnapshot;
};

export default useSalesShops;
