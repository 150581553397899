export default class Utils {
  static isRefundableOrder(order) {
    return (
      order.cooked_at &&
      !order.refunded_at &&
      order.amount > 0 &&
      order.paid &&
      (order.stripe_charge_id || order.stripe_payment_intent_id || order.paypay || order.square_payment_id)
    );
  }

  static orderStatusName(status) {
    switch (status) {
      case 'ordered':
        return '調理待ち';
      case 'cooking':
        return '調理中';
      case 'canceled':
        return 'キャンセル';
      case 'cooked':
        return '調理完了';
      case 'received':
        return '受取完了';
      default:
        return status;
    }
  }

  static toppingStatusName(status) {
    let name = '';
    switch (status) {
      case 'available':
        name = '通常有料販売';
        break;
      case 'soldout':
        name = '販売停止';
        break;
      case 'free':
        name = '無料販売';
        break;
      default:
        name = '不明';
    }
    return name;
  }

  static isNumber(value: any) {
    return typeof value === 'number' && Number.isFinite(value);
  }
}
