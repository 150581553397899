export default interface LoadingStockEntity {
  shop_id: string;
  calculation_date: string; // YYYY-MM-DD
  loading_date: string; // YYYY-MM-DD
  consumption_date: string; // YYYY-MM-DD
  element_items: {
    id: string;
    required_quantity: number;
    quantity: number;
    current_stock: number;
    current_planned_waste: number;
    reset: boolean;
    config: any; // MealForecastConfigEntity
  }[];
}

// Helper function to sort LoadingStockEntity.element_items by kitchen_order
export function sortElementItemsByKitchenOrder(
  loadingStock: LoadingStockEntity,
  elementItemsMap: { [key: string]: number }, // Map of element_item_id to kitchen_order
): LoadingStockEntity {
  if (!loadingStock.element_items || !elementItemsMap) return loadingStock;

  const sortedElementItems = [...loadingStock.element_items].sort((a, b) => {
    const orderA = elementItemsMap[a.id] ?? Number.MAX_SAFE_INTEGER;
    const orderB = elementItemsMap[b.id] ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  return {
    ...loadingStock,
    element_items: sortedElementItems,
  };
}
