export type OfficeDeliverySegment = {
  value: string;
  label: string;
};

export const OfficeDeliverySegments: OfficeDeliverySegment[] = [
  { value: 'segment5', label: 'セグメント5' },
  { value: 'segment10', label: 'セグメント10' },
  { value: 'segment15', label: 'セグメント15' },
  { value: 'segment20', label: 'セグメント20' },
  { value: 'irregular', label: 'イレギュラー' },
];
