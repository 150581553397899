import React, { useState, useMemo, useContext } from 'react';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import firebase from './firebase';
import useLoadingStocks from './hooks/useLoadingStocks';
import LoadingStockEntity, { sortElementItemsByKitchenOrder } from './types/LoadingStockEntity';
import dayjs from './dayjs';
import ShopsContext from './ShopsContext';
import useElementItems from './hooks/useElementItems';

function LoadingStocksList() {
  const loadingStocksSnapshot = useLoadingStocks();
  const [executing, setExecuting] = useState(false);
  const { shops } = useContext(ShopsContext);
  const elementItems = useElementItems(false); // Get all element items, including disabled ones

  // Create a map of element item IDs to kitchen_order values
  const elementItemsOrderMap = useMemo(() => {
    if (!elementItems) return {};

    const orderMap: { [key: string]: number } = {};
    elementItems.forEach((item) => {
      const { id } = item;
      const kitchenOrder = item.data()?.kitchen_order ?? Number.MAX_SAFE_INTEGER;
      orderMap[id] = kitchenOrder;
    });

    return orderMap;
  }, [elementItems]);

  // Function to get shop name from shop ID
  const getShopName = (shopId: string) => {
    if (shops[shopId]) {
      return shops[shopId].data()?.short_name || shopId;
    }
    return shopId;
  };

  // Calculate tomorrow's date for comparison
  const tomorrow = dayjs().tz('Asia/Tokyo').add(1, 'day').format('YYYY-MM-DD');
  // Calculate today's date for comparison
  const today = dayjs().tz('Asia/Tokyo').format('YYYY-MM-DD');

  // Group loading stocks by loading_date
  const groupedByLoadingDate = useMemo(() => {
    if (!loadingStocksSnapshot) return null;

    interface GroupedStocks {
      [loadingDate: string]: {
        snapshot: firebase.firestore.DocumentData;
        data: LoadingStockEntity;
      }[];
    }

    const grouped: GroupedStocks = {};

    loadingStocksSnapshot.forEach((snapshot) => {
      const data = snapshot.data() as LoadingStockEntity;
      // Sort element_items by kitchen_order
      const sortedData = sortElementItemsByKitchenOrder(data, elementItemsOrderMap);
      const loadingDate = sortedData.loading_date;

      if (!grouped[loadingDate]) {
        grouped[loadingDate] = [];
      }

      grouped[loadingDate].push({
        snapshot,
        data: sortedData,
      });
    });

    // Convert to array and sort by loading_date
    return Object.keys(grouped)
      .sort((a, b) => b.localeCompare(a)) // Sort in descending order
      .map((date) => ({
        loadingDate: date,
        stocks: grouped[date],
        isDeletable: date >= today,
      }));
  }, [loadingStocksSnapshot, today, elementItemsOrderMap]);

  const handleDeleteGroup = async (
    loadingDate: string,
    stocks: { snapshot: firebase.firestore.DocumentData; data: LoadingStockEntity }[],
  ) => {
    // Only allow deletion if loading_date is today or later
    if (loadingDate < today) return;

    const options = {
      title: '確認',
      message: `積み込み日 ${loadingDate} の在庫データ (${stocks.length}件) をすべて削除します。よろしいですか？`,
      buttons: [
        {
          label: '削除する',
          onClick: async () => {
            try {
              setExecuting(true);

              // Create a batch to delete multiple documents
              const batch = firebase.firestore().batch();

              stocks.forEach((item) => {
                batch.delete(item.snapshot.ref);
              });

              await batch.commit();
            } finally {
              setExecuting(false);
            }
          },
        },
        {
          label: 'キャンセル',
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  const handleDeleteSingle = async (loadingStockSnapshot) => {
    const options = {
      title: '確認',
      message: `${loadingStockSnapshot.data().loading_date}の在庫データを削除します。よろしいですか？`,
      buttons: [
        {
          label: '削除する',
          onClick: async () => {
            try {
              setExecuting(true);
              await loadingStockSnapshot.ref.delete();
            } finally {
              setExecuting(false);
            }
          },
        },
        {
          label: 'キャンセル',
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  // Function to check if a record can be deleted (only if loading_date is today or later)
  const canDelete = (stockData: LoadingStockEntity) => {
    return stockData.loading_date >= today;
  };

  return (
    <>
      <WindmillSpinnerOverlay loading={executing} />
      <div className="container mt-3">
        <h1>積み込み予定/実績一覧</h1>
        <p className="text-muted">
          過去2週間から先の積み込み予定データを表示しています。今日以降の予定のみ削除可能です。
        </p>

        {groupedByLoadingDate ? (
          groupedByLoadingDate.length > 0 ? (
            <div className="accordion" id="loadingStocksAccordion">
              {groupedByLoadingDate.map((group, index) => (
                <div className="card" key={group.loadingDate}>
                  <div className="card-header d-flex justify-content-between align-items-center" id={`heading${index}`}>
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target={`#collapse${index}`}
                        aria-expanded="true"
                        aria-controls={`collapse${index}`}
                      >
                        積み込み日: {group.loadingDate} ({group.stocks.length}店舗)
                      </button>
                    </h5>
                    <div>
                      <Link to={`/admin/loading_stocks/${group.loadingDate}`} className="btn btn-info btn-sm mr-2">
                        詳細
                      </Link>
                      {group.isDeletable && (
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteGroup(group.loadingDate, group.stocks)}
                        >
                          一括削除
                        </button>
                      )}
                    </div>
                  </div>

                  <div
                    id={`collapse${index}`}
                    className={`collapse ${index === 0 ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-parent="#loadingStocksAccordion"
                  >
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>店舗</th>
                              <th>計算日</th>
                              <th>積み込み日</th>
                              <th>提供日</th>
                              <th>操作</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group.stocks.map((item) => {
                              const isDeletable = canDelete(item.data);

                              return (
                                <tr key={item.snapshot.id}>
                                  <td>{getShopName(item.data.shop_id)}</td>
                                  <td>{item.data.calculation_date}</td>
                                  <td>{item.data.loading_date}</td>
                                  <td>{item.data.consumption_date}</td>
                                  <td>
                                    {isDeletable ? (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={() => handleDeleteSingle(item.snapshot)}
                                      >
                                        削除
                                      </button>
                                    ) : (
                                      <button type="button" className="btn btn-sm btn-secondary" disabled>
                                        削除不可
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="alert alert-info">表示できる積み込み予定データがありません</div>
          )
        ) : (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default LoadingStocksList;
