import React, { useState, useEffect, CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import dayjs from './dayjs';
import AdminMessageNav from './AdminMessageNav';
import Utils from './Utils';

// テーブルのスタイル
const tableContainerStyle: CSSProperties = {
  position: 'relative',
  height: 'calc(100vh - 150px)',
  overflow: 'auto',
  width: '100%',
};

const tableStyle: CSSProperties = {
  width: 'max-content',
  minWidth: '100%',
};

// 詳細表示オフ時のテーブルスタイル
const basicTableStyle: CSSProperties = {
  width: '100%',
  tableLayout: 'fixed',
  wordBreak: 'break-word',
};

const stickyHeaderStyle: CSSProperties = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 10,
  boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.1)',
};

// テキスト制限用スタイル
const truncatedTextStyle: CSSProperties = {
  maxHeight: '100px',
  overflow: 'hidden',
  position: 'relative',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
};

const tooltipStyle: CSSProperties = {
  position: 'absolute',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: 'white',
  padding: '5px 10px',
  borderRadius: '4px',
  zIndex: 100,
  maxWidth: '400px',
  maxHeight: '300px',
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};

// 詳細表示オフ時のカラム幅設定
const columnWidths = {
  edit: { width: '40px' },
  type: { width: '100px' },
  customers: { width: '80px' },
  category: { width: '50px' },
  shop: { width: '100px' },
  office: { width: '100px' },
  office_delivery_segments: { width: '100px' },
  channel: { width: '80px' },
  title: { width: '120px' },
  body: { width: '180px' },
  coupon: { width: '80px' },
  image: { width: '80px' },
  cover: { width: '80px' },
  action: { width: '100px' },
};

function ScheduledMessages() {
  // localStorageから前回の状態を取得、なければデフォルトでfalse
  const getSavedState = () => {
    const savedState = localStorage.getItem('showAdditionalFields');
    return savedState === 'true';
  };

  const [messages, setMessages] = useState<any>();
  const [showAdditionalFields, setShowAdditionalFields] = useState(getSavedState());
  const [expandedMessages, setExpandedMessages] = useState<Record<string, boolean>>({});
  const [expandedShops, setExpandedShops] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const unregisterMessagesObserver = firebase
      .firestore()
      .collection('scheduled_messages')
      .where('status', '==', 'scheduled')
      .orderBy('send_at', 'desc')
      .onSnapshot((snap) => {
        console.log('onSnapshot ScheduledMessages');
        loadOtherContent(snap);
      });

    return () => {
      unregisterMessagesObserver();
    };
  }, []);

  async function loadOtherContent(snap) {
    const records = {};
    for (const docSnapshot of snap.docs) {
      const data = docSnapshot.data();

      if (data.payload.segment.shop_ids) {
        data.shop_name = (
          await Promise.all(
            data.payload.segment.shop_ids.map(async (shopId) => {
              if (shopId === 'OTHERS') {
                return 'どの店舗にも属していない人';
              }
              const shop = await firebase.firestore().collection('shops').doc(shopId).get();
              return shop.data()!.short_name;
            }),
          )
        ).join(', ');
      }

      if (data.payload.message.coupon_id) {
        const coupon = await firebase.firestore().collection('coupons').doc(data.payload.message.coupon_id).get();
        data.coupon_code = coupon.data()!.code;
      }

      records[docSnapshot.id] = data;
    }

    setMessages(records);
  }

  const submit = (messageId) => {
    firebase.firestore().collection('scheduled_messages').doc(messageId).delete();
  };

  const emptyDiv = () => <div />;

  const cancelScheduledMessage = (messageId) => {
    const options = {
      title: '送信予約のキャンセルを行います。',
      buttons: [
        {
          label: 'はい',
          onClick: () => {
            submit(messageId);
          },
        },
        {
          label: 'いいえ',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const notificationChannelsMap = { order: 'オーダー', marketing: 'お知らせ' };

  const toggleAdditionalFields = () => {
    const newState = !showAdditionalFields;
    setShowAdditionalFields(newState);
    // localStorageに状態を保存
    localStorage.setItem('showAdditionalFields', newState.toString());
  };

  // テキストを制限する関数
  const truncateText = (text, maxLines = 5) => {
    if (!text) return '-';
    const lines = text.split('\n');
    if (lines.length <= maxLines) return text;

    return `${lines.slice(0, maxLines).join('\n')}...`;
  };

  // メッセージの展開/折りたたみを切り替える関数
  const toggleMessageExpand = (messageId) => {
    setExpandedMessages({
      ...expandedMessages,
      [messageId]: !expandedMessages[messageId],
    });
  };

  // 店舗の展開/折りたたみを切り替える関数
  const toggleShopExpand = (messageId) => {
    setExpandedShops({
      ...expandedShops,
      [messageId]: !expandedShops[messageId],
    });
  };

  // 基本情報のテーブルヘッダー
  const renderBasicTableHeader = () => (
    <thead>
      <tr>
        {/* ヘッダーセルにスタイルを適用 */}
        <th style={{ ...stickyHeaderStyle, ...columnWidths.edit }}>-</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.type }}>タイプ</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.customers }}>送信予定者数</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.category }}>種別</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.shop }}>送信対象店舗</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.office }}>オフィス送信(親店舗別)</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.office_delivery_segments }}>法人デリバリーセグメント</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.channel }}>通知チャンネル</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.title }}>タイトル</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.body }}>本文</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.coupon }}>クーポン</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.image }}>通知画像</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.cover }}>カバー画像</th>
        <th style={{ ...stickyHeaderStyle, ...columnWidths.action }}>操作</th>
      </tr>
    </thead>
  );

  // 詳細情報のテーブルヘッダー
  const renderDetailedTableHeader = () => (
    <thead>
      <tr>
        {/* ヘッダーセルにスタイルを適用 */}
        <th style={stickyHeaderStyle}>-</th>
        <th style={stickyHeaderStyle}>タイプ</th>
        <th style={stickyHeaderStyle}>送信予定者数</th>
        <th style={stickyHeaderStyle}>種別</th>
        <th style={stickyHeaderStyle}>送信対象店舗</th>
        <th style={stickyHeaderStyle}>オフィス送信(親店舗別)</th>
        <th style={stickyHeaderStyle}>法人デリバリーセグメント</th>
        <th style={stickyHeaderStyle}>通知チャンネル</th>
        <th style={stickyHeaderStyle}>タイトル</th>
        <th style={stickyHeaderStyle}>本文</th>
        <th style={stickyHeaderStyle}>クーポン</th>
        <th style={stickyHeaderStyle}>通知画像</th>
        <th style={stickyHeaderStyle}>カバー画像</th>

        {/* セグメント情報 */}
        <th style={stickyHeaderStyle}>購入地点の店舗からの距離(内側)</th>
        <th style={stickyHeaderStyle}>購入地点の店舗からの距離(外側)</th>
        <th style={stickyHeaderStyle}>クーポンボックスにある</th>
        <th style={stickyHeaderStyle}>クーポンボックスにない</th>
        <th style={stickyHeaderStyle}>クーポン使用済み</th>
        <th style={stickyHeaderStyle}>クーポン未使用</th>
        <th style={stickyHeaderStyle}>注文回数</th>
        <th style={stickyHeaderStyle}>最終注文からの日数</th>
        <th style={stickyHeaderStyle}>期間内注文</th>
        <th style={stickyHeaderStyle}>最終注文時間</th>
        <th style={stickyHeaderStyle}>前回注文金額</th>
        <th style={stickyHeaderStyle}>最終スロット時間</th>
        <th style={stickyHeaderStyle}>アプリDL日時(前)</th>
        <th style={stickyHeaderStyle}>アプリDL日時(後)</th>
        <th style={stickyHeaderStyle}>スタンプカード回数</th>

        {/* その他設定 */}
        <th style={stickyHeaderStyle}>プリセット</th>
        <th style={stickyHeaderStyle}>タグ</th>
        <th style={stickyHeaderStyle}>注文中ユーザー除外</th>
        <th style={stickyHeaderStyle}>スロットなし除外</th>
        <th style={stickyHeaderStyle}>アップリフトモデリング</th>
        <th style={stickyHeaderStyle}>ユーザーID指定</th>
        <th style={stickyHeaderStyle}>アプリバージョン</th>
        <th style={stickyHeaderStyle}>グループ指定</th>
        <th style={stickyHeaderStyle}>共有オプション</th>
        <th style={stickyHeaderStyle}>チームボーナス</th>
        <th style={stickyHeaderStyle}>強制通知</th>
        <th style={stickyHeaderStyle}>有効期限</th>

        <th style={stickyHeaderStyle}>操作</th>
      </tr>
    </thead>
  );

  // 基本情報の行を描画
  const renderBasicTableRows = () => (
    <tbody>
      {Object.keys(messages).map((messageId) => (
        <tr key={`message-basic-${messageId}`}>
          <td>
            <NavLink to={`/admin/messages/${messageId}`}>編集</NavLink>
          </td>
          <td>
            {messages[messageId].send_option === 'time_specified' && (
              <>
                <div>時間指定</div>
                <div>{dayjs(messages[messageId].send_at.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}</div>
                {messages[messageId].send2_at && (
                  <div>{dayjs(messages[messageId].send2_at.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}</div>
                )}
                {messages[messageId].send3_at && (
                  <div>{dayjs(messages[messageId].send3_at.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}</div>
                )}
              </>
            )}
            {messages[messageId].send_option === 'batch_delivery_reminder' && '法人デリバリーリマインダー'}
            {messages[messageId].send_option === 'hakase' && (
              <>
                <div>博士ロジック</div>
                <div>{messages[messageId].hakase_send_date}</div>
              </>
            )}
            {messages[messageId].input_type && (
              <div style={{ marginTop: '3px', fontSize: '0.85em', color: 'gray' }}>
                (from {messages[messageId].input_type})
              </div>
            )}
          </td>
          <td>
            {Utils.isNumber(messages[messageId].number_of_customers_planed)
              ? messages[messageId].number_of_customers_planed
              : '-'}
          </td>
          <td>{messages[messageId].payload.segment.customer_ids ? '個別' : '全体'}</td>
          <td style={{ wordWrap: 'break-word', maxWidth: '100px', overflow: 'hidden' }}>
            {messages[messageId].shop_name || '-'}
          </td>
          <td style={{ wordWrap: 'break-word', maxWidth: '100px', overflow: 'hidden' }}>
            {messages[messageId].batch_delivery_parent_shop_name || '-'}
          </td>
          <td style={{ wordWrap: 'break-word', maxWidth: '100px', overflow: 'hidden' }}>
            {messages[messageId].payload.segment.office_delivery_segments?.join(', ') || '-'}
          </td>
          <td>{notificationChannelsMap[messages[messageId].payload.message.notification_channel]}</td>
          <td style={{ wordWrap: 'break-word' }}>{messages[messageId].payload.message.subject}</td>
          <td>
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden' }}>
              {messages[messageId].payload.message.body}
            </pre>
          </td>
          <td>{messages[messageId].coupon_code || '-'}</td>
          <td>
            {messages[messageId].payload.message.image_url ? (
              <img
                alt={messages[messageId].payload.message.image_url}
                src={messages[messageId].payload.message.image_url}
                height="50px"
              />
            ) : (
              'なし'
            )}
          </td>
          <td>
            {messages[messageId].payload.message.cover_image_url ? (
              <img
                alt={messages[messageId].payload.message.cover_image_url}
                src={messages[messageId].payload.message.cover_image_url}
                height="50px"
              />
            ) : (
              'なし'
            )}
          </td>
          <td>
            <button type="button" className="btn btn-danger btn-sm" onClick={() => cancelScheduledMessage(messageId)}>
              予約キャンセル
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  );

  // 詳細情報の行を描画
  const renderDetailedTableRows = () => (
    <tbody>
      {Object.keys(messages).map((messageId) => (
        <tr key={`message-detailed-${messageId}`}>
          <td>
            <NavLink to={`/admin/messages/${messageId}`}>編集</NavLink>
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            {messages[messageId].send_option === 'time_specified' && (
              <>
                <div>時間指定</div>
                <div>{dayjs(messages[messageId].send_at.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}</div>
                {messages[messageId].send2_at && (
                  <div>{dayjs(messages[messageId].send2_at.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}</div>
                )}
                {messages[messageId].send3_at && (
                  <div>{dayjs(messages[messageId].send3_at.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}</div>
                )}
              </>
            )}
            {messages[messageId].send_option === 'batch_delivery_reminder' && '法人デリバリーリマインダー'}
            {messages[messageId].send_option === 'hakase' && (
              <>
                <div>博士ロジック</div>
                <div>{messages[messageId].hakase_send_date}</div>
              </>
            )}
            {messages[messageId].input_type && (
              <div style={{ marginTop: '3px', fontSize: '0.85em', color: 'gray' }}>
                (from {messages[messageId].input_type})
              </div>
            )}
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            {Utils.isNumber(messages[messageId].number_of_customers_planed)
              ? messages[messageId].number_of_customers_planed
              : '-'}
          </td>
          <td>{messages[messageId].payload.segment.customer_ids ? '個別' : '全体'}</td>
          <td style={{ wordWrap: 'break-word', maxWidth: '100px', overflow: 'hidden' }}>
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  maxHeight: expandedShops[messageId] ? 'none' : '100px',
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
              >
                {messages[messageId].shop_name || '-'}
              </div>
              {!expandedShops[messageId] &&
                messages[messageId].shop_name &&
                messages[messageId].shop_name.length > 50 && (
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    onClick={() => toggleShopExpand(messageId)}
                    style={{ padding: '0', marginTop: '2px' }}
                  >
                    more
                  </button>
                )}
              {expandedShops[messageId] && (
                <button
                  type="button"
                  className="btn btn-sm btn-link"
                  onClick={() => toggleShopExpand(messageId)}
                  style={{ padding: '0', marginTop: '2px' }}
                >
                  less
                </button>
              )}
            </div>
          </td>
          <td style={{ wordWrap: 'break-word', maxWidth: '100px', overflow: 'hidden' }}>
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  maxHeight: expandedShops[`parent_${messageId}`] ? 'none' : '100px',
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
              >
                {messages[messageId].batch_delivery_parent_shop_name || '-'}
              </div>
              {!expandedShops[`parent_${messageId}`] &&
                messages[messageId].batch_delivery_parent_shop_name &&
                messages[messageId].batch_delivery_parent_shop_name.length > 50 && (
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    onClick={() => toggleShopExpand(`parent_${messageId}`)}
                    style={{ padding: '0', marginTop: '2px' }}
                  >
                    more
                  </button>
                )}
              {expandedShops[`parent_${messageId}`] && (
                <button
                  type="button"
                  className="btn btn-sm btn-link"
                  onClick={() => toggleShopExpand(`parent_${messageId}`)}
                  style={{ padding: '0', marginTop: '2px' }}
                >
                  less
                </button>
              )}
            </div>
          </td>
          <td style={{ wordWrap: 'break-word', maxWidth: '100px', overflow: 'hidden' }}>
            {messages[messageId].payload.segment.office_delivery_segments?.join(', ') || '-'}
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            {notificationChannelsMap[messages[messageId].payload.message.notification_channel]}
          </td>
          <td>{messages[messageId].payload.message.subject}</td>
          <td style={{ maxWidth: '180px', overflow: 'hidden' }}>
            <div style={{ position: 'relative' }}>
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  maxHeight: expandedMessages[messageId] ? 'none' : '100px',
                  margin: 0,
                }}
              >
                {messages[messageId].payload.message.body}
              </pre>
              {!expandedMessages[messageId] &&
                messages[messageId].payload.message.body &&
                messages[messageId].payload.message.body.split('\n').length > 5 && (
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    onClick={() => toggleMessageExpand(messageId)}
                    style={{ padding: '0', marginTop: '2px' }}
                  >
                    more
                  </button>
                )}
              {expandedMessages[messageId] && (
                <button
                  type="button"
                  className="btn btn-sm btn-link"
                  onClick={() => toggleMessageExpand(messageId)}
                  style={{ padding: '0', marginTop: '2px' }}
                >
                  less
                </button>
              )}
            </div>
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>{messages[messageId].coupon_code || '-'}</td>
          <td style={{ whiteSpace: 'nowrap' }}>
            {messages[messageId].payload.message.image_url ? (
              <img
                alt={messages[messageId].payload.message.image_url}
                src={messages[messageId].payload.message.image_url}
                height="50px"
              />
            ) : (
              'なし'
            )}
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            {messages[messageId].payload.message.cover_image_url ? (
              <img
                alt={messages[messageId].payload.message.cover_image_url}
                src={messages[messageId].payload.message.cover_image_url}
                height="50px"
              />
            ) : (
              'なし'
            )}
          </td>

          {/* セグメント情報 */}
          <td>{messages[messageId].payload.segment.distance || '-'}</td>
          <td>{messages[messageId].payload.segment.distance_outside || '-'}</td>
          <td>{messages[messageId].payload.segment.coupon_added || '-'}</td>
          <td>{messages[messageId].payload.segment.coupon_not_added || '-'}</td>
          <td>{messages[messageId].payload.segment.coupon_used || '-'}</td>
          <td>{messages[messageId].payload.segment.coupon_not_used || '-'}</td>
          <td>{messages[messageId].payload.segment.number_of_curries_done || '-'}</td>
          <td>{messages[messageId].payload.segment.days_after_last_visit || '-'}</td>
          <td>
            {messages[messageId].payload.segment.days_recent_visit
              ? `${messages[messageId].payload.segment.days_recent_visit}日 (${
                  messages[messageId].payload.segment.days_recent_visit_minimum_orders || '1'
                }回以上)`
              : '-'}
          </td>
          <td>
            {messages[messageId].payload.segment.last_order_hhmm_from
              ? `${messages[messageId].payload.segment.last_order_hhmm_from} ~ ${
                  messages[messageId].payload.segment.last_order_hhmm_to || ''
                }`
              : '-'}
          </td>
          <td>{messages[messageId].payload.segment.last_order_price || '-'}</td>
          <td>
            {messages[messageId].payload.segment.last_pickup_hhmm_from
              ? `${messages[messageId].payload.segment.last_pickup_hhmm_from} ~ ${
                  messages[messageId].payload.segment.last_pickup_hhmm_to || ''
                }`
              : '-'}
          </td>
          <td>
            {messages[messageId].payload.segment.joined_before
              ? dayjs
                  .unix(messages[messageId].payload.segment.joined_before)
                  .tz('Asia/Tokyo')
                  .format('YYYY-MM-DD HH:mm')
              : '-'}
          </td>
          <td>
            {messages[messageId].payload.segment.joined_after
              ? dayjs.unix(messages[messageId].payload.segment.joined_after).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
              : '-'}
          </td>
          <td>{messages[messageId].payload.segment.stamp_counts || '-'}</td>

          {/* その他設定 */}
          <td>
            {Array.isArray(messages[messageId].payload.segment.sales_preset_ids)
              ? messages[messageId].payload.segment.sales_preset_ids.join(', ')
              : '-'}
          </td>
          <td>
            {Array.isArray(messages[messageId].payload.segment.tag_ids)
              ? messages[messageId].payload.segment.tag_ids.join(', ')
              : '-'}
          </td>
          <td>{messages[messageId].payload.segment.exclude_ordering ? '✓' : '-'}</td>
          <td>
            {messages[messageId].payload.segment.exclude_no_slots
              ? `✓ ${messages[messageId].payload.segment.exclude_no_slots_date || '当日'}`
              : '-'}
          </td>
          <td>{messages[messageId].payload.segment.uplift_modeling || '-'}</td>
          <td>
            {Array.isArray(messages[messageId].payload.segment.customer_ids)
              ? messages[messageId].payload.segment.customer_ids.join(', ')
              : '-'}
          </td>
          <td>{messages[messageId].payload.segment.app_version || '-'}</td>
          <td>
            {Array.isArray(messages[messageId].payload.segment.groups)
              ? messages[messageId].payload.segment.groups.join(', ')
              : 'すべて'}
          </td>
          <td>{messages[messageId].payload.message.shareable ? '✓' : '-'}</td>
          <td>{messages[messageId].team_bonus ? '✓' : '-'}</td>
          <td>{messages[messageId].force_notification ? '✓' : '-'}</td>
          <td>
            {messages[messageId].expires_at
              ? dayjs.unix(messages[messageId].expires_at).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
              : '-'}
          </td>

          <td style={{ whiteSpace: 'nowrap' }}>
            <button type="button" className="btn btn-danger" onClick={() => cancelScheduledMessage(messageId)}>
              予約キャンセル
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className="container-fluid h-100">
      <AdminMessageNav />
      <h3>送信予約メッセージ</h3>

      <div className="card mb-4">
        <div className="card-body">
          <div className="mb-3">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="detailSwitch"
                checked={showAdditionalFields}
                onChange={toggleAdditionalFields}
              />
              <label className="custom-control-label" htmlFor="detailSwitch">
                {showAdditionalFields ? '詳細項目を表示中' : '詳細項目を表示'}
              </label>
            </div>
          </div>

          <div id="messages" className="container-fluid">
            {messages === undefined ? (
              <div className="row">
                <div className="col text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {Object.keys(messages).length === 0 ? (
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-light text-center" role="alert">
                        ありません
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={tableContainerStyle}>
                    <table
                      className="table table-striped table-bordered"
                      style={!showAdditionalFields ? basicTableStyle : tableStyle}
                    >
                      {!showAdditionalFields ? renderBasicTableHeader() : renderDetailedTableHeader()}
                      {!showAdditionalFields ? renderBasicTableRows() : renderDetailedTableRows()}
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduledMessages;
