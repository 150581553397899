import React, { useContext, useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';
import firebase from './firebase';
import ShopsContext from './ShopsContext';
import salesChannels from './SalesChannels';

function AllSlots() {
  const [date, setDate] = useQueryParam('date', StringParam);
  const [salesChannel, setSalesChannel] = useQueryParam('sales_channel', StringParam);
  const [sortBy, setSortBy] = useQueryParam('sort_by', withDefault(StringParam, 'shop'));
  const [slots, setSlots] = useState<Array<any>>();
  const [slotSets, setSlotSets] = useState<Record<string, any>>({});
  const { shops } = useContext(ShopsContext);

  useEffect(() => {
    const dayjsDate = date ? dayjs.tz(date, 'Asia/Tokyo') : dayjs().tz('Asia/Tokyo');
    const startOfDay = dayjsDate.startOf('day').toDate();
    const endOfDay = dayjsDate.endOf('day').toDate();

    // スロットセット情報を取得
    const fetchSlotSets = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collectionGroup('slot_sets')
          .where('time', '>=', startOfDay)
          .where('time', '<', endOfDay)
          .get();

        const records = {};
        snapshot.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });

        setSlotSets(records);
        return records;
      } catch (error) {
        console.error('Error fetching slot sets:', error);
        return {};
      }
    };

    // スロット情報を取得
    const fetchSlots = async (slotSetsData) => {
      const snapshot = await firebase
        .firestore()
        .collectionGroup('slots')
        .where('open', 'in', [true, false])
        .where('time', '>=', startOfDay)
        .where('time', '<', endOfDay)
        .orderBy('time')
        .get();

      const records: Array<any> = [];

      snapshot.forEach((docSnapshot) => {
        if (!salesChannel || shops[docSnapshot.data().shop_id].data()!.sales_channels?.includes(salesChannel)) {
          records.push(docSnapshot);
        }
      });

      if (sortBy === 'shop') {
        records.sort((a, b) => {
          const shopA = shops[a.data().shop_id].data()!;
          const shopB = shops[b.data().shop_id].data()!;

          // まず店舗のorderで並び替え
          if (shopA.order < shopB.order) return -1;
          if (shopA.order > shopB.order) return 1;

          // 同じ店舗の場合、slotsetの時間で並び替え
          const slotSetA = slotSetsData[a.data().slot_set_id];
          const slotSetB = slotSetsData[b.data().slot_set_id];

          if (slotSetA && slotSetB) {
            const slotSetTimeA = slotSetA.time.toDate().getTime();
            const slotSetTimeB = slotSetB.time.toDate().getTime();

            if (slotSetTimeA < slotSetTimeB) return -1;
            if (slotSetTimeA > slotSetTimeB) return 1;
          }

          // 同じslotsetの場合、スロットの時間で並び替え
          return a.data().time.toDate().getTime() - b.data().time.toDate().getTime();
        });
      }

      setSlots(records);
    };

    // データ取得を実行
    const fetchData = async () => {
      const slotSetsData = await fetchSlotSets();
      await fetchSlots(slotSetsData);
    };

    fetchData();
  }, [date, shops, salesChannel, sortBy]);

  const dateChanged = (e) => {
    setDate(e.target.value);
  };

  const salesChannelChanged = (e) => {
    setSalesChannel(e.target.value);
  };

  const sortByChanged = (e) => {
    setSortBy(e.target.value);
  };

  const renderSlot = (slot: firebase.firestore.DocumentSnapshot) => {
    const data = slot.data()!;

    const kitchenShop = shops[data.kitchen_shop_id];
    const shop = shops[data.shop_id];
    const cols: Array<JSX.Element> = [];

    // 親店舗+slotsetの時間を表示（最初の列）- 店舗順の場合のみ
    if (sortBy === 'shop') {
      if (data.showKitchenShopAndSlotSet) {
        const slotSet = slotSets[data.slot_set_id];
        const slotSetTime = slotSet ? dayjs(slotSet.time.toDate()).tz('Asia/Tokyo').format('HH:mm') : '';

        cols.push(
          <td className="border-right" style={{ whiteSpace: 'nowrap' }}>
            <span className="font-weight-bold">{kitchenShop.data()!.short_name}</span>
            <span className="badge badge-secondary ml-2">{slotSetTime}</span>
          </td>,
        );
      } else {
        cols.push(<td />);
      }
    }

    // 店舗名を表示
    const isChildShop = kitchenShop.id !== shop.id;
    let shopDisplay = '';

    if (sortBy === 'shop') {
      // 店舗順の場合は子店舗名のみ表示
      shopDisplay = isChildShop ? shop.data()!.short_name : kitchenShop.data()!.short_name;
    } else {
      // 時間順の場合は親店舗名+子店舗名を表示
      shopDisplay = isChildShop
        ? `${kitchenShop.data()!.short_name} - ${shop.data()!.short_name}`
        : kitchenShop.data()!.short_name;
    }

    cols.push(
      <td>
        <span className="position-relative d-inline-block" style={{ whiteSpace: 'nowrap' }}>
          {shopDisplay}
        </span>
      </td>,
    );

    // 時間を表示
    cols.push(
      <td className="text-right" style={{ whiteSpace: 'nowrap' }}>
        <span className="font-weight-bold">
          {dayjs(data.time.toDate()).tz('Asia/Tokyo').format('HH:mm')}-
          {dayjs(data.time_until.toDate()).tz('Asia/Tokyo').format('HH:mm')}
        </span>
        {data.batch_delivery_close_at && (
          <span className="text-danger ml-1 small">
            {` (〆${dayjs(data.batch_delivery_close_at.toDate()).tz('Asia/Tokyo').format('HH:mm')})`}
          </span>
        )}
      </td>,
    );

    // takeoutのときはtimeoutがセットされないため時間で判断(直したい)
    const status =
      data.timeout || new Date(+data.time_until.toDate() - 5 * 60 * 1000) < new Date()
        ? 'timeout'
        : !data.open
        ? 'soldout'
        : data.capacity - (data.ordered ?? 0) < 3
        ? 'soon'
        : null;

    cols.push(
      <td className="text-right" style={{ whiteSpace: 'nowrap' }}>
        {data.ordered ?? 0} / {data.capacity ?? 0}
      </td>,
    );

    let statusNode: JSX.Element;
    switch (status) {
      case 'timeout':
        statusNode = (
          <span className="badge badge-dark" role="alert">
            終了
          </span>
        );
        break;
      case 'soldout':
        statusNode = (
          <span className="badge badge-danger" role="alert">
            売り切れ
          </span>
        );
        break;
      case 'soon':
        statusNode = (
          <span className="badge badge-warning" role="alert">
            売り切れ間近
          </span>
        );
        break;
      default:
        statusNode = (
          <span className="badge badge-primary" role="alert">
            受付中
          </span>
        );
    }

    cols.push(
      <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
        {statusNode}
      </td>,
    );

    return cols;
  };

  // 同じslotsetの時間のスロットをグループ化して表示するための関数
  const renderSlots = () => {
    if (!slots) return null;

    if (sortBy !== 'shop') {
      return slots.map((slot) => <tr key={`${slot.data().shop_id}-${slot.id}`}>{renderSlot(slot)}</tr>);
    }

    // グループごとに表示
    const result: JSX.Element[] = [];
    let lastKitchenShopId = '';
    let lastSlotSetTime = '';

    // まず親店舗（kitchen_shop_id）でグループ化
    const kitchenShopGroups: Record<string, Array<any>> = {};

    slots.forEach((slot) => {
      const kitchenShopId = slot.data().kitchen_shop_id;

      if (!kitchenShopGroups[kitchenShopId]) {
        kitchenShopGroups[kitchenShopId] = [];
      }

      kitchenShopGroups[kitchenShopId].push(slot);
    });

    // 親店舗のorderでソート
    const sortedKitchenShops = Object.keys(kitchenShopGroups).sort((a, b) => {
      const shopA = shops[a].data()!;
      const shopB = shops[b].data()!;
      return shopA.order - shopB.order;
    });

    // 親店舗ごとに処理
    sortedKitchenShops.forEach((kitchenShopId) => {
      const slotsInKitchenShop = kitchenShopGroups[kitchenShopId];

      // slotsetでグループ化
      const slotSetGroups: Record<string, Array<any>> = {};

      slotsInKitchenShop.forEach((slot) => {
        const slotSetId = slot.data().slot_set_id;

        if (!slotSetGroups[slotSetId]) {
          slotSetGroups[slotSetId] = [];
        }

        slotSetGroups[slotSetId].push(slot);
      });

      // slotsetの時間でソート
      const sortedSlotSets = Object.keys(slotSetGroups).sort((a, b) => {
        const slotSetA = slotSets[a];
        const slotSetB = slotSets[b];

        if (slotSetA && slotSetB) {
          return slotSetA.time.toDate().getTime() - slotSetB.time.toDate().getTime();
        }

        return 0;
      });

      // slotsetごとに処理
      sortedSlotSets.forEach((slotSetId) => {
        const slotsInSlotSet = slotSetGroups[slotSetId];

        // 子店舗でグループ化
        const shopGroups: Record<string, Array<any>> = {};

        slotsInSlotSet.forEach((slot) => {
          const shopId = slot.data().shop_id;

          if (!shopGroups[shopId]) {
            shopGroups[shopId] = [];
          }

          shopGroups[shopId].push(slot);
        });

        // 子店舗のorderでソート
        const sortedShops = Object.keys(shopGroups).sort((a, b) => {
          const shopA = shops[a].data()!;
          const shopB = shops[b].data()!;
          return shopA.order - shopB.order;
        });

        // 親店舗名を取得
        const kitchenShop = shops[kitchenShopId];
        const kitchenShopName = kitchenShop.data()!.short_name;

        // slotsetの時間を取得
        const slotSet = slotSets[slotSetId];
        const slotSetTime = slotSet ? dayjs(slotSet.time.toDate()).tz('Asia/Tokyo').format('HH:mm') : '';

        // 同じ親店舗かチェック
        const isSameKitchenShop = kitchenShopId === lastKitchenShopId;

        // 同じslotSetの時間かチェック
        const isSameSlotSetTime = slotSetTime === lastSlotSetTime;

        // 現在の値を保存
        lastKitchenShopId = kitchenShopId;
        lastSlotSetTime = slotSetTime;

        // 全スロットを配列にまとめる
        const allSlots: Array<any> = [];

        // 親店舗自身のスロットを先頭に
        sortedShops.forEach((shopId) => {
          const slotsInShop = shopGroups[shopId];

          // スロットの時間でソート
          slotsInShop.sort((a, b) => {
            return a.data().time.toDate().getTime() - b.data().time.toDate().getTime();
          });

          // 親店舗のスロットを先頭に
          if (shopId === kitchenShopId) {
            allSlots.push(...slotsInShop);
          }
        });

        // 子店舗のスロットを追加
        sortedShops.forEach((shopId) => {
          if (shopId !== kitchenShopId) {
            const slotsInShop = shopGroups[shopId];
            allSlots.push(...slotsInShop);
          }
        });

        // 最初のスロットだけ親店舗+slotsetの時間を表示
        if (allSlots.length > 0) {
          const firstSlot = allSlots[0];
          const firstSlotData = {
            ...firstSlot.data(),
            showKitchenShopAndSlotSet: true,
          };

          const firstSlotWithMeta = {
            ...firstSlot,
            data: () => firstSlotData,
          };

          result.push(<tr key={`${firstSlot.data().shop_id}-${firstSlot.id}`}>{renderSlot(firstSlotWithMeta)}</tr>);

          // 残りのスロットは親店舗+slotsetの時間を表示しない
          for (let i = 1; i < allSlots.length; i += 1) {
            const slot = allSlots[i];
            const slotData = {
              ...slot.data(),
              showKitchenShopAndSlotSet: false,
            };

            const slotWithMeta = {
              ...slot,
              data: () => slotData,
            };

            result.push(<tr key={`${slot.data().shop_id}-${slot.id}`}>{renderSlot(slotWithMeta)}</tr>);
          }
        }
      });
    });

    return result;
  };

  return (
    <div className="container">
      <div className="mb-3">
        <input type="date" onChange={dateChanged} defaultValue={date ?? ''} className="mr-2" />

        <select className="form-control-sm mr-2" defaultValue={salesChannel || ''} onChange={salesChannelChanged}>
          <option key="all" value="">
            すべて
          </option>
          {salesChannels?.map((channel) => (
            <option key={channel.value} value={channel.value}>
              {channel.name}
            </option>
          ))}
        </select>

        <select className="form-control-sm" defaultValue={sortBy || 'shop'} onChange={sortByChanged}>
          <option value="time">時間順</option>
          <option value="shop">親店舗+店舗スロット順</option>
        </select>
      </div>

      {slots === undefined ? (
        <div className="text-center py-3">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-sm table-hover" style={{ whiteSpace: 'nowrap' }}>
            <thead className="bg-light">
              <tr>
                {sortBy === 'shop' && <th style={{ width: '20%' }}>親店舗</th>}
                <th style={{ width: sortBy === 'shop' ? '20%' : '30%' }}>店舗</th>
                <th style={{ width: sortBy === 'shop' ? '25%' : '35%' }} className="text-right">
                  スロット
                </th>
                <th style={{ width: '15%' }} className="text-right">
                  注文数
                </th>
                <th style={{ width: '20%' }} className="text-center">
                  状態
                </th>
              </tr>
            </thead>
            <tbody>{renderSlots()}</tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AllSlots;
