import React, { useEffect, useState, useContext } from 'react';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { useParams, useHistory } from 'react-router-dom';
import firebase from './firebase';
import LoadingStockEntity, { sortElementItemsByKitchenOrder } from './types/LoadingStockEntity';
import ShopsContext from './ShopsContext';
import { MealForecastConfigEntity } from './types/MealForecastConfigEntity';
import useElementItems from './hooks/useElementItems';

function LoadingStockDetail() {
  const { loadingDate } = useParams<{ loadingDate: string }>();
  const [loadingStocks, setLoadingStocks] = useState<Array<{ id: string; data: LoadingStockEntity }>>();
  const [loading, setLoading] = useState(true);
  const { shops } = useContext(ShopsContext);
  const history = useHistory();
  const elementItems = useElementItems(false); // Get all element items, including disabled ones

  // Create a map of element item IDs to kitchen_order values
  const elementItemsOrderMap = React.useMemo(() => {
    if (!elementItems) return {};

    const orderMap: { [key: string]: number } = {};
    elementItems.forEach((item) => {
      const { id } = item;
      const kitchenOrder = item.data()?.kitchen_order ?? Number.MAX_SAFE_INTEGER;
      orderMap[id] = kitchenOrder;
    });

    return orderMap;
  }, [elementItems]);

  // Function to get shop name from shop ID
  const getShopName = (shopId: string) => {
    if (shops[shopId]) {
      return shops[shopId].data()?.short_name || shopId;
    }
    return shopId;
  };

  // Function to get element item name from ID
  const getElementItemName = (itemId: string) => {
    if (!elementItems) return itemId;

    const elementItem = elementItems.find((item) => item.id === itemId || item.data()?.id === itemId);

    if (elementItem) {
      return `${elementItem.data()?.name || itemId} (${itemId})`;
    }

    return itemId;
  };

  // Get specification method label
  const getSpecificationMethodLabel = (method: string) => {
    switch (method) {
      case 'absolute_value':
        return '絶対値';
      case 'percent_of_meal_count':
        return '食数比率';
      default:
        return method;
    }
  };

  // Get carryover stock label
  const getCarryoverStockLabel = (value: string) => {
    switch (value) {
      case 'yes':
        return 'あり';
      case 'no':
        return 'なし';
      default:
        return value;
    }
  };

  // Format day of week array
  const formatDaysOfWeek = (days: number[]) => {
    if (!days || days.length === 0) return 'なし';

    const dayLabels = ['日', '月', '火', '水', '木', '金', '土'];
    return days.map((day) => dayLabels[day]).join(', ');
  };

  useEffect(() => {
    const fetchLoadingStocks = async () => {
      try {
        setLoading(true);
        const snapshot = await firebase
          .firestore()
          .collection('loading_stocks')
          .where('loading_date', '==', loadingDate)
          .get();

        const records = snapshot.docs.map((doc) => {
          const loadingStockData = doc.data() as LoadingStockEntity;
          // Sort the element_items by kitchen_order
          const sortedData = sortElementItemsByKitchenOrder(loadingStockData, elementItemsOrderMap);

          return {
            id: doc.id,
            data: sortedData,
          };
        });

        setLoadingStocks(records);
      } finally {
        setLoading(false);
      }
    };

    if (loadingDate && elementItems) {
      fetchLoadingStocks();
    }
  }, [loadingDate, elementItems, elementItemsOrderMap]);

  return (
    <>
      <WindmillSpinnerOverlay loading={loading} />
      <div className="container mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1>積み込み日 {loadingDate} の詳細</h1>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => history.push('/admin/loading_stocks')}
          >
            一覧に戻る
          </button>
        </div>

        {loadingStocks && loadingStocks.length > 0 ? (
          <>
            {loadingStocks.map((record) => (
              <div key={record.id} className="card mb-4">
                <div className="card-header">
                  <h5 className="mb-0">店舗: {getShopName(record.data.shop_id)}</h5>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-md-4">
                        <strong>計算日:</strong> {record.data.calculation_date}
                      </div>
                      <div className="col-md-4">
                        <strong>積み込み日:</strong> {record.data.loading_date}
                      </div>
                      <div className="col-md-4">
                        <strong>提供日:</strong> {record.data.consumption_date}
                      </div>
                    </div>
                  </div>

                  <h6 className="border-bottom pb-2 mb-3">アイテム一覧</h6>

                  {record.data.element_items && record.data.element_items.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th>アイテム</th>
                            <th>提供予定数</th>
                            <th>積み込み数</th>
                            <th>
                              計算時
                              <br />
                              在庫数
                            </th>
                            <th>
                              計算時
                              <br />
                              廃棄予定棄
                            </th>
                            <th>リセット</th>
                            <th>設定詳細</th>
                          </tr>
                        </thead>
                        <tbody>
                          {record.data.element_items.map((item) => (
                            <tr key={item.id}>
                              <td>{getElementItemName(item.id)}</td>
                              <td>{item.required_quantity}</td>
                              <td>{item.quantity}</td>
                              <td>{item.current_stock}</td>
                              <td>{item.current_planned_waste}</td>
                              <td>{item.reset ? 'あり' : 'なし'}</td>
                              <td>
                                {item.config && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-info"
                                    data-toggle="modal"
                                    data-target={`#configModal-${record.id}-${item.id}`}
                                  >
                                    設定表示
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="alert alert-info">アイテムが登録されていません</div>
                  )}

                  {/* Modals for config details */}
                  {record.data.element_items &&
                    record.data.element_items.map(
                      (item) =>
                        item.config && (
                          <div
                            key={`modal-${item.id}`}
                            className="modal fade"
                            id={`configModal-${record.id}-${item.id}`}
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby={`configModalLabel-${item.id}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id={`configModalLabel-${item.id}`}>
                                    設定詳細: {getElementItemName(item.id)}
                                  </h5>
                                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <table className="table table-sm">
                                    <tbody>
                                      <tr>
                                        <th scope="row">数量指定方法</th>
                                        <td>
                                          {getSpecificationMethodLabel(item.config.quantity_specification_method)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">繰越在庫</th>
                                        <td>{getCarryoverStockLabel(item.config.carryover_stock)}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">補充閾値</th>
                                        <td>{item.config.replenishment_threshold ?? 'なし'}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">ロット切り上げ数</th>
                                        <td>{item.config.lot_ceiling_number ?? 'なし'}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">在庫リセット曜日</th>
                                        <td>{formatDaysOfWeek(item.config.stock_reset_day_of_week)}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">調整数</th>
                                        <td>{item.config.adjust_stocks ?? 0}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    閉じる
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                    )}
                </div>
              </div>
            ))}
          </>
        ) : loading ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="alert alert-info">この積み込み日のデータはありません</div>
        )}
      </div>
    </>
  );
}

export default LoadingStockDetail;
