import React from 'react';
import { NavLink } from 'react-router-dom';

function AdminMessageNav() {
  return (
    <nav className="nav nav-tabs mb-4">
      <NavLink to="/admin/messages" className="nav-link" activeClassName="active">
        メッセージ送信
      </NavLink>
      <NavLink to="/admin/scheduled_messages/" className="nav-link" activeClassName="active">
        送信予約メッセージ
      </NavLink>
      <NavLink to="/admin/send_message_from_sheet" className="nav-link" activeClassName="active">
        Google Sheetsから一括送信
      </NavLink>
      <NavLink to="/admin/file_upload" className="nav-link" activeClassName="active">
        ファイルアップロード
      </NavLink>
      <NavLink to="/admin/inventory_history" className="nav-link" activeClassName="active">
        在庫履歴
      </NavLink>
    </nav>
  );
}

export default AdminMessageNav;
