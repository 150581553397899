import React, { useState, useEffect, CSSProperties, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useQueryParam, StringParam } from 'use-query-params';
import dayjs from './dayjs';
import useElementItems from './hooks/useElementItems';

// 最低限必要なカスタムスタイル
const tableContainerStyle: CSSProperties = {
  position: 'relative',
  height: 'calc(100vh - 250px)',
  overflow: 'auto',
  width: '100%', // 親要素一杯に広げる
};

// Bootstrapの横スクロール対応のため、テーブル自体の幅を指定
const tableStyle: CSSProperties = {
  width: 'max-content', // テーブルが持つ内容の幅を保持
  minWidth: '100%', // 最低でも親要素の100%を確保
  borderCollapse: 'separate',
  borderSpacing: 0,
};

const stickyHeaderStyle: CSSProperties = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 10,
};

const stickyHeaderSecondRowStyle: CSSProperties = {
  position: 'sticky',
  top: '38px', // 1行目のヘッダー高さに合わせて調整
  backgroundColor: 'white',
  zIndex: 9,
};

const stickyFirstColumnStyle: CSSProperties = {
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
  zIndex: 5,
};

// 左上コーナーのセルスタイル
const cornerCellStyle: CSSProperties = {
  position: 'sticky',
  left: 0,
  top: 0,
  backgroundColor: 'white',
  zIndex: 15, // 他のすべての要素より前面に表示
};

// 左上コーナーの2行目セルスタイル
const cornerCellSecondRowStyle: CSSProperties = {
  position: 'sticky',
  left: 0,
  top: '38px',
  backgroundColor: 'white',
  zIndex: 14, // 1行目よりも低く、他の要素よりも高く
};

// 数値セルの共通スタイル
const numberCellStyle: CSSProperties = {
  textAlign: 'right',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  minWidth: '80px',
};

function InventoryHistory() {
  const [loading, setLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState<any>(null);
  const [dateTimeParam, setDateTimeParam] = useQueryParam('datetime', StringParam);
  const [dateTime, setDateTime] = useState<string>(
    dateTimeParam || dayjs().tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm'),
  );
  const [shops, setShops] = useState<any>({});
  const [error, setError] = useState<string>('');
  const [foodCategories, setFoodCategories] = useState<string[]>([]);

  const elementItems = useElementItems(false);

  const sortCategoriesByElementItemOrder = useCallback(
    (categories: string[]) => {
      if (!elementItems) return categories;

      // ElementItemsからkitchen_nameとkitchen_orderのマッピングを作成
      const itemOrderMap = new Map<string, number>();
      elementItems.forEach((item) => {
        const data = item.data();
        if (data && data.kitchen_name) {
          // kitchen_orderが設定されている場合はそれを使用
          itemOrderMap.set(
            data.kitchen_name,
            data.kitchen_order !== undefined ? data.kitchen_order : Number.MAX_SAFE_INTEGER,
          );
        }
      });

      const sortedCategories = [...categories].sort((a, b) => {
        const orderA = itemOrderMap.has(a) ? itemOrderMap.get(a)! : Number.MAX_SAFE_INTEGER;
        const orderB = itemOrderMap.has(b) ? itemOrderMap.get(b)! : Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });

      return sortedCategories;
    },
    [elementItems],
  );

  // 在庫データ取得
  const fetchInventoryData = useCallback(async () => {
    if (!dateTime) {
      setError('日時を入力してください');
      return;
    }

    // 表示ボタンクリック時にURLパラメータを更新
    setDateTimeParam(dateTime);

    setLoading(true);
    setError('');

    try {
      const timestamp = dayjs.tz(dateTime, 'Asia/Tokyo').unix();
      const auth = firebase.auth();
      const token = await auth.currentUser!.getIdToken();

      // APIエンドポイント
      const apiEndPoint = `${process.env.REACT_APP_api_server}/inventory/history?timestamp=${timestamp}`;

      console.log(`API呼び出し: ${apiEndPoint}`);

      const response = await fetch(apiEndPoint, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || `APIエラー: HTTPステータス ${response.status}`);
      }

      const data = await response.json();
      console.log('在庫データ取得成功:', Object.keys(data).length, '店舗分のデータ');
      setInventoryData(data);

      const categories = new Set<string>();
      Object.values(data).forEach((shopData: any) => {
        Object.keys(shopData).forEach((category) => {
          categories.add(category);
        });
      });

      const sortedCategories = sortCategoriesByElementItemOrder(Array.from(categories));
      setFoodCategories(sortedCategories);
    } catch (err: any) {
      console.error('在庫データ取得エラー:', err);
      setError(err.message || '在庫データの取得に失敗しました');
    } finally {
      setLoading(false);
    }
  }, [dateTime, setDateTimeParam, sortCategoriesByElementItemOrder]);

  // 初期ロード時、URLパラメータがある場合のみデータ取得
  useEffect(() => {
    if (dateTimeParam) {
      // URLパラメータが存在する場合、その日時でデータを取得
      setDateTime(dateTimeParam);
      fetchInventoryData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ElementItemsが読み込まれたときの処理
  useEffect(() => {
    if (elementItems && elementItems.length > 0 && inventoryData) {
      // ElementItemsが読み込まれた後、既存データのカテゴリーを再ソート
      console.log('ElementItems読み込み完了、カテゴリーを再ソート');

      const categories = new Set<string>();
      Object.values(inventoryData).forEach((shopData: any) => {
        Object.keys(shopData).forEach((category) => {
          categories.add(category);
        });
      });

      const sortedCategories = sortCategoriesByElementItemOrder(Array.from(categories));
      setFoodCategories(sortedCategories);
    }
  }, [elementItems, inventoryData, sortCategoriesByElementItemOrder]);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .where('kitchen_shop_id', '==', null)
      .where('status', '==', 'active')
      .orderBy('order')
      .onSnapshot((snap) => {
        const shopRecords = {};
        snap.forEach((docSnapshot) => {
          shopRecords[docSnapshot.id] = docSnapshot.data();
        });
        setShops(shopRecords);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  // テーブルヘッダーのレンダリング
  const renderTableHeader = () => (
    <thead className="bg-light">
      <tr>
        <th className="align-middle" style={cornerCellStyle}>
          店舗
        </th>
        {foodCategories.map((category) => (
          <th key={category} className="text-center align-middle" style={stickyHeaderStyle} colSpan={4}>
            {category}
          </th>
        ))}
      </tr>
      <tr>
        <th style={cornerCellSecondRowStyle} aria-label="店舗" />
        {foodCategories.map((category) => (
          <React.Fragment key={`${category}-details`}>
            <th className="text-center small" style={stickyHeaderSecondRowStyle}>
              在庫数
            </th>
            <th className="text-center small" style={stickyHeaderSecondRowStyle}>
              内本日注文済
            </th>
            <th className="text-center small" style={stickyHeaderSecondRowStyle}>
              明日以降注文済
            </th>
            <th className="text-center small" style={stickyHeaderSecondRowStyle}>
              注文可能在庫数
            </th>
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );

  // テーブル本体のレンダリング
  const renderTableRows = () => (
    <tbody>
      {Object.keys(shops).map((shopId) => (
        <tr key={`shop-${shopId}`}>
          <td className="fw-bold" style={stickyFirstColumnStyle}>
            {shops[shopId].short_name}
          </td>

          {foodCategories.map((category) => {
            const shopData = inventoryData?.[shopId] || {};
            const categoryData = shopData[category] || {
              consumed: 0,
              consumed_fromnow: 0,
              total: 0,
              consumed_tomorrow: 0,
            };
            const totalValue = categoryData.total || 0;
            const consumedValue = categoryData.consumed || 0;
            const consumedFromnowValue = categoryData.consumed_fromnow || 0;
            const consumedTomorrowValue = categoryData.consumed_tomorrow || 0;

            const stockValue = Math.max(0, totalValue - consumedValue + consumedFromnowValue);

            const todayOrderedValue = Math.max(0, consumedFromnowValue - consumedTomorrowValue);

            const availableStockValue = Math.max(0, totalValue - (consumedValue - consumedTomorrowValue));

            return (
              <React.Fragment key={`${shopId}-${category}`}>
                <td style={numberCellStyle}>{stockValue}</td>
                <td style={numberCellStyle}>{todayOrderedValue}</td>
                <td style={numberCellStyle}>{consumedTomorrowValue}</td>
                <td style={{ ...numberCellStyle, fontWeight: 'bold' }}>{availableStockValue}</td>
              </React.Fragment>
            );
          })}
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className="container-fluid h-100">
      <h3>在庫履歴</h3>

      <div className="card mb-4">
        <div className="card-body">
          <div className="form-group row mb-3">
            <label htmlFor="inputDateTime" className="col-sm-2 col-form-label">
              日時を指定（日本時間）
            </label>
            <div className="col-sm-4">
              <input
                type="datetime-local"
                id="inputDateTime"
                className="form-control"
                value={dateTime}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setDateTime(newValue);
                  // setDateTimeParam(newValue); // URLパラメータは表示ボタンクリック時のみ更新
                }}
                aria-label="日時を指定（日本時間）"
              />
              <small className="form-text text-muted">表示される在庫データは指定した日本時間時点のものになります</small>
            </div>
            <div className="col-sm-3">
              <button type="button" className="btn btn-primary" onClick={fetchInventoryData} disabled={loading}>
                {loading ? '読み込み中...' : '表示'}
              </button>
            </div>
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {inventoryData && (
            <div style={{ width: '100%', height: 'calc(100vh - 250px)' }}>
              <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <table className="table table-sm table-striped table-bordered" style={tableStyle}>
                  {renderTableHeader()}
                  {renderTableRows()}
                </table>
              </div>
            </div>
          )}

          {loading && (
            <div className="text-center my-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {!loading && !inventoryData && !error && (
            <div className="alert alert-light text-center" role="alert">
              日時を選択して「表示」ボタンを押してください
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InventoryHistory;
