import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import dayjs from './dayjs';
import Utils from './Utils';
import ShopsContext from './ShopsContext';
import { StoreContainer } from './store';
import salesChannels from './SalesChannels';

function OrderSummary(props: { orderId: string }) {
  const storeContainer = StoreContainer.useContainer();
  const [order, setOrder] = useState<any>();
  const { shops } = useContext(ShopsContext);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('orders')
      .doc(props.orderId)
      .onSnapshot((snap) => {
        console.log('onSnapshot OrderSummary');
        setOrder(snap.data());
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [storeContainer.shopId, props.orderId]);

  return (
    <>
      {order ? (
        <>
          <span className="pr-2">
            {Utils.orderStatusName(order.status)}

            {order.cooked_at && <> ({dayjs(order.cooked_at.toDate()).tz('Asia/Tokyo').format('HH:mm')})</>}
          </span>

          <span className="pr-2">
            <NavLink to={`/orders/${props.orderId}`}>No. {order.code}</NavLink>
          </span>

          <span className="pr-2">{order.nickname}</span>

          <span className="pr-2">
            <i className="far fa-clock" />
            &nbsp;
            {dayjs((order.estimated_at || order.pickup_until_at).toDate())
              .tz('Asia/Tokyo')
              .format('HH:mm')}
          </span>

          <span className="pr-2">{shops[order.shop_id].data()!.short_name}</span>

          <span className="pr-2">
            ({salesChannels.find((salesChannel) => salesChannel.value === order.sales_channel)?.shortName})
          </span>
        </>
      ) : null}
    </>
  );
}

export default OrderSummary;
