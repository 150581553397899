import { useEffect, useState } from 'react';
import firebase from '../firebase';
import dayjs from '../dayjs';

const useLoadingStocks = (): Array<firebase.firestore.DocumentData> | null => {
  const [loadingStocksSnapshot, setLoadingStocksSnapshot] = useState<Array<firebase.firestore.DocumentData> | null>(
    null,
  );

  useEffect(() => {
    // Get date from 2 weeks ago
    const twoWeeksAgo = dayjs().tz('Asia/Tokyo').subtract(2, 'weeks').format('YYYY-MM-DD');

    const query = firebase
      .firestore()
      .collection('loading_stocks')
      .where('loading_date', '>=', twoWeeksAgo)
      .orderBy('loading_date', 'desc');

    const unregister = query.onSnapshot((snapshot) => {
      console.log('onSnapshot useLoadingStocks');
      setLoadingStocksSnapshot(snapshot.docs);
    });

    return () => {
      unregister();
    };
  }, []);

  return loadingStocksSnapshot;
};

export default useLoadingStocks;
