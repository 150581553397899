import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useSalesPresets = (type?: string): Array<firebase.firestore.DocumentData> | null => {
  const [useSalesPresetsSnapshot, setSalesPresetsSnapshot] = useState<Array<firebase.firestore.DocumentData> | null>(
    null,
  );

  useEffect(() => {
    let query = firebase.firestore().collection('sales_presets').where('archived', '==', false);
    if (type) {
      query = query.where('curry_recipe_type', '==', type);
    }
    query = query.orderBy('order');
    query.get().then((snapshot) => {
      setSalesPresetsSnapshot(snapshot.docs);
    });
  }, [type]);

  return useSalesPresetsSnapshot;
};

export default useSalesPresets;
