import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Modal from 'react-modal';
import dayjs from './dayjs';
import { StoreContainer } from './store';

Modal.setAppElement('#root');

function NotificationBadge() {
  const storeContainer = StoreContainer.useContainer();
  const [badge, setBadge] = useState<number>(0);
  const [modalItems, setModalItems] = useState<Array<{ id: string; message: string }>>([]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    const batch = firebase.firestore().batch();
    for (const item of modalItems) {
      batch.update(
        firebase.firestore().collection('shops').doc(storeContainer.shopId).collection('notifications').doc(item.id),
        {
          confirmed: true,
          confirmed_at: new Date(),
          crew_id: storeContainer.crewId,
        },
      );
    }
    batch.commit();
    setModalItems([]);
  };

  useEffect(() => {
    let unregisterNotificationBadgeObserver;
    if (storeContainer.shopId) {
      unregisterNotificationBadgeObserver = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('notifications')
        .where('confirmed', '==', false)
        .where('created_at', '>=', dayjs().tz('Asia/Tokyo').startOf('day').toDate())
        .onSnapshot((snap) => {
          console.log('onSnapshot NotificationBadge');
          const changeDocs = snap.docChanges();
          const items: Array<{ id: string; message: string }> = [];
          for (const change of changeDocs) {
            if (change.type === 'added') {
              if (change.doc.data().modal) {
                items.push({
                  id: change.doc.id,
                  message: change.doc.data().message,
                });
              }
            }
          }
          if (items.length > 0) {
            setModalItems(items);
          }

          setBadge(snap.size);
        });
    }

    return () => {
      if (unregisterNotificationBadgeObserver) {
        unregisterNotificationBadgeObserver();
      }
    };
  }, [storeContainer.shopId]);

  return (
    <>
      <NavLink to="/notifications">
        <span className="badge badge-danger">{badge > 0 ? badge : ''}</span>
      </NavLink>

      {modalItems.length > 0 ? (
        <Modal
          isOpen
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form id="refund_form">
            <div className="form-group">
              <ul>
                {modalItems.map((item) => (
                  <li key={item.id}>{item.message}</li>
                ))}
              </ul>
              <br />
              <button type="button" className="btn btn-primary float-right" onClick={closeModal}>
                確認しました
              </button>
            </div>
          </form>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default NotificationBadge;
