// eslint-disable-next-line max-classes-per-file
import React, { useContext, useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-datasheet/lib/react-datasheet.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ShopsContext from './ShopsContext';
import firebase from './firebase';
import Utils from './Utils';
import useSalesShops from './hooks/useSalesShops';
import useSalesPresets from './hooks/useSalesPresets';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number | string> {
  value: number | null | string;
  handler?: QrBentoHandler;
  width?: number;
  readOnly?: boolean;
}

class MyReactDataSheet extends ReactDataSheet<GridElement, number | string> {}

interface AppState {
  grid: GridElement[][];
}

class QrBentoHandler {
  shopId: string;

  salesPresetId: string;

  isPrice: boolean;

  constructor(shopId: string, salesPresetId: string, isPrice: boolean = false) {
    this.shopId = shopId;
    this.salesPresetId = salesPresetId;
    this.isPrice = isPrice;
  }

  update(value: number | null, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('qr_bento_plans').doc(`${this.shopId}-${this.salesPresetId}`);

    const updateData: any = {
      shop_id: this.shopId,
      sales_preset_id: this.salesPresetId,
    };

    if (this.isPrice) {
      updateData.price = value;
    } else {
      updateData.max_quantity = value;
    }

    batch.set(ref, updateData, { merge: true });
    return true;
  }
}

class ShopQrBento {
  shopId: string;

  maxQuantities = {};

  prices = {};

  name: string;

  order: number;

  constructor(shopId: string, name: string, order: number) {
    this.shopId = shopId;
    this.name = name;
    this.order = order;
  }

  updateValue(salesPresetId: string, value: number | null, isPrice: boolean = false) {
    if (isPrice) {
      this.prices[salesPresetId] = value;
    } else {
      this.maxQuantities[salesPresetId] = value;
    }
  }
}

function transpose(a, defaultValue) {
  return Object.keys(a[0]).map((c) => {
    return a.map((r) => {
      return r[c] || defaultValue;
    });
  });
}

function ShopQrBentoPlanUpdate() {
  const { shops } = useContext(ShopsContext);
  const [shopQrBentos, setShopQrBentos] = useState<{ [key: string]: ShopQrBento }>();
  const salesPresets = useSalesPresets('qr_bento');
  const salesShops = useSalesShops();

  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection('qr_bento_plans')
      .get()
      .then((snapshot) => {
        const newShopQrBentos: { [key: string]: ShopQrBento } = {};

        for (const shop of Object.keys(shops)
          .map((shopId) => shops[shopId])
          .filter((s) => !s.data()!.kitchen_shop_id && s.data()!.status === 'active')) {
          newShopQrBentos[shop.id] = new ShopQrBento(shop.id, shop.data()!.short_name, shop.data()!.order);
        }

        for (const doc of snapshot.docs) {
          const plan = doc.data()!;
          const shopId = plan.shop_id;

          if (!newShopQrBentos[shopId]) {
            continue;
          }

          const shopRecipe = newShopQrBentos[shopId];

          if (plan.sales_preset_id) {
            shopRecipe.updateValue(plan.sales_preset_id, plan.max_quantity, false);
            shopRecipe.updateValue(plan.sales_preset_id, plan.price, true);
          }
        }

        setShopQrBentos(newShopQrBentos);
      });
  }, [shops]);

  useEffect(() => {
    if (shopQrBentos && salesPresets && salesShops) {
      const grid: GridElement[][] = [];

      // Create first header row with shop names
      const headerRow1: GridElement[] = [{ value: '', width: 200, readOnly: true }];
      Object.values(shopQrBentos)
        .sort((a, b) => a.order - b.order)
        .forEach((shop) => {
          // Add shop name and empty cell for price
          headerRow1.push({ value: shop.name, width: 150, readOnly: true });
          headerRow1.push({ value: '', width: 150, readOnly: true });
        });

      // Create second header row with quantity/price labels
      const headerRow2: GridElement[] = [{ value: '', width: 200, readOnly: true }];
      Object.values(shopQrBentos)
        .sort((a, b) => a.order - b.order)
        .forEach(() => {
          headerRow2.push({ value: '個数', width: 150, readOnly: true });
          headerRow2.push({ value: '価格', width: 150, readOnly: true });
        });

      grid.push(headerRow1);
      grid.push(headerRow2);

      // Create rows for each preset
      salesPresets.forEach((preset) => {
        const row: GridElement[] = [{ value: preset.data()!.name, width: 200, readOnly: true }];

        Object.values(shopQrBentos)
          .sort((a, b) => a.order - b.order)
          .forEach((shop) => {
            const foundSalesShop = salesShops.find(
              (salesShop) =>
                salesShop.data()!.shop_id === shop.shopId &&
                salesShop.data()!.qr_bento_sales_preset_ids.includes(preset.id),
            );

            // Quantity cell
            row.push({
              value: shop.maxQuantities[preset.id],
              width: 150,
              readOnly: !foundSalesShop,
              handler: new QrBentoHandler(shop.shopId, preset.id, false),
            } as GridElement);

            // Price cell
            row.push({
              value: shop.prices[preset.id],
              width: 150,
              readOnly: !foundSalesShop,
              handler: new QrBentoHandler(shop.shopId, preset.id, true),
            } as GridElement);
          });

        grid.push(row);
      });

      setData(grid);
    }
  }, [shopQrBentos, salesPresets, salesShops]);

  const onGridRowsUpdated = (changes) => {
    setData((prevData) => {
      const batch = firebase.firestore().batch();
      const grid = prevData.map((row) => [...row]);
      changes.forEach(({ cell, row, col, value }) => {
        let updatingValue: null | number = null;
        let valid = false;
        if (value === '') {
          updatingValue = null;
          valid = true;
        } else {
          const intValue = parseInt(value, 10);
          if (Utils.isNumber(intValue)) {
            updatingValue = intValue;
            valid = true;
          }
        }

        if (valid) {
          grid[row][col] = { ...grid[row][col], value: updatingValue };

          if (cell.handler) {
            cell.handler.update(updatingValue, batch);
          }
        }
      });

      batch.commit();
      return grid;
    });
  };

  return (
    <>
      <div style={{ marginBottom: '5px', color: '#666' }}>
        ※ 価格は税込みです / 価格を入力しない場合は定価になります
      </div>
      {salesPresets && shopQrBentos && (
        <MyReactDataSheet
          className="reset-bootstrap-for-datasheet"
          data={data}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={onGridRowsUpdated}
        />
      )}
    </>
  );
}

export default ShopQrBentoPlanUpdate;
