import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useSalesItems = (): Array<firebase.firestore.DocumentData> | null => {
  const [useSalesItemsSnapshot, setSalesItemsSnapshot] = useState<Array<firebase.firestore.DocumentData> | null>(null);

  useEffect(() => {
    const query = firebase.firestore().collection('sales_items').where('archived', '==', false).orderBy('order');

    const unregister = query.onSnapshot((snapshot) => {
      console.log('onSnapshot useSalesItems');
      setSalesItemsSnapshot(snapshot.docs);
    });

    return () => {
      unregister();
    };
  }, []);

  return useSalesItemsSnapshot;
};

export default useSalesItems;
