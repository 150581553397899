import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import dayjs from './dayjs';
import ShopsContext from './ShopsContext';
import SlotDeliveryGroupLimitCurrries from './SlotDeliveryGroupLimitCurrries';

function ShopSlots(props: { slotSetId: string; kitchenShopId: string }) {
  const { shops } = useContext(ShopsContext);
  const [slots, setSlots] = useState<any>();
  const [slotSet, setSlotSet] = useState<firebase.firestore.DocumentSnapshot>();

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collectionGroup('slots')
      .where('kitchen_shop_id', '==', props.kitchenShopId)
      .where('slot_set_id', '==', props.slotSetId)
      .onSnapshot((snap) => {
        console.log('onSnapshot ShopSlots');
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.ref.path] = docSnapshot;
        });

        setSlots(records);
      });

    const unregisterSlotSetsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(props.kitchenShopId)
      .collection('slot_sets')
      .doc(props.slotSetId)
      .onSnapshot((snap) => {
        console.log('onSnapshot ShopSlots slot_set');
        setSlotSet(snap);
      });

    return () => {
      unregisterShopsObserver();
      unregisterSlotSetsObserver();
    };
  }, [props.kitchenShopId, props.slotSetId]);

  return (
    <div className="container-fluid h-100">
      {slotSet && (
        <small>
          デリバリーグループ配達上限数
          <ul>
            {slotSet.data()?.delivery_groups?.map((group) => (
              <li key={`${props.kitchenShopId}-${slotSet.id}-${group.id}`}>
                <SlotDeliveryGroupLimitCurrries slotSet={slotSet} group={group} />
              </li>
            ))}
          </ul>
        </small>
      )}
      {slots && (
        <>
          {Object.keys(slots).map((id) => (
            <div key={id}>
              <div className="pr-2">
                {shops[slots[id].data().shop_id].data()!.short_name}
                <br />
                <div className="ml-2">
                  {dayjs(slots[id].data().time.toDate()).tz('Asia/Tokyo').format('HH:mm')}-
                  {dayjs(slots[id].data().time_until.toDate()).tz('Asia/Tokyo').format('HH:mm')}
                  {slots[id].data().batch_delivery_close_at &&
                    dayjs(slots[id].data().batch_delivery_close_at.toDate()).tz('Asia/Tokyo').format('(〆HH:mm)')}
                  &nbsp; 残り {slots[id].data().available}
                  {slots[id].data().delivery_group && (
                    <small>
                      <br />
                      {slots[id].data().delivery_group?.name}{' '}
                      {slots[id].data().delivery_group?.limit_curries_per_slot ?? '-'}食
                    </small>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default ShopSlots;
